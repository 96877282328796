/* _|   _ \   __|    __|
  |    __/   _|   \__ \        _|  (_-<  (_-<
___|  _|    _|    ____/  _)  \__|  ___/  ___/

See: https://github.com/ipfs-shipyard/ipfs-css
*/

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: local('Inter UI'), local('InterUI-Regular'), url("fonts/Inter-UI-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: local('Inter UI Italic'), local('InterUI-Italic'), url("fonts/Inter-UI-Italic.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 500;
  src: local('Inter UI Medium'), local('InterUI-Medium'), url("fonts/Inter-UI-Medium.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 500;
  src: local('Inter UI Medium Italic'), local('InterUI-MediumItalic'), url("fonts/Inter-UI-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  src: local('Inter UI Bold'), local('InterUI-Bold'), url("fonts/Inter-UI-Bold.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: local('Inter UI Bold Italic'), local('InterUI-BoldItalic'), url("fonts/Inter-UI-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url('fonts/Montserrat-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url('fonts/Montserrat-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('fonts/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url('fonts/Montserrat-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('fonts/Montserrat-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url('fonts/Montserrat-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('fonts/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url('fonts/Montserrat-BoldItalic.woff2') format('woff2');
}

/* IPFS theme.css - Generated from https://github.com/ipfs-shipyard/ipfs-css/blob/master/theme.json */

/* ---- font ----- */

.sans-serif { font-family: 'Inter UI', system-ui, sans-serif; }

.montserrat { font-family: 'Montserrat', 'Verdana', system-ui, sans-serif; }

.monospace { font-family: Consolas, monaco, monospace; }

/* ---- color ----- */

.navy { color: #0b3a53; }

.navy-muted { color: #244e66; }

.aqua { color: #69c4cd; }

.aqua-muted { color: #9ad4db; }

.gray { color: #b7bbc8; }

.gray-muted { color: #d9dbe2; }

.charcoal { color: #34373f; }

.charcoal-muted { color: #7f8491; }

.red { color: #ea5037; }

.red-muted { color: #f36149; }

.yellow { color: #f39021; }

.yellow-muted { color: #f9a13e; }

.teal { color: #3e9096; }

.teal-muted { color: #439a9d; }

.green { color: #0cb892; }

.green-muted { color: #0aca9f; }

.snow { color: #edf0f4; }

.snow-muted { color: #f7f8fa; }

.bg-navy { background-color: #0b3a53; }

.bg-navy-muted { background-color: #244e66; }

.bg-aqua { background-color: #69c4cd; }

.bg-aqua-muted { background-color: #9ad4db; }

.bg-gray { background-color: #b7bbc8; }

.bg-gray-muted { background-color: #d9dbe2; }

.bg-charcoal { background-color: #34373f; }

.bg-charcoal-muted { background-color: #7f8491; }

.bg-red { background-color: #ea5037; }

.bg-red-muted { background-color: #f36149; }

.bg-yellow { background-color: #f39021; }

.bg-yellow-muted { background-color: #f9a13e; }

.bg-teal { background-color: #3e9096; }

.bg-teal-muted { background-color: #439a9d; }

.bg-green { background-color: #0cb892; }

.bg-green-muted { background-color: #0aca9f; }

.bg-snow { background-color: #edf0f4; }

.bg-snow-muted { background-color: #f7f8fa; }

.hover-navy:hover, .hover-navy:focus { color: #0b3a53; }

.hover-navy-muted:hover, .hover-navy-muted:focus { color: #244e66; }

.hover-aqua:hover, .hover-aqua:focus { color: #69c4cd; }

.hover-aqua-muted:hover, .hover-aqua-muted:focus { color: #9ad4db; }

.hover-gray:hover, .hover-gray:focus { color: #b7bbc8; }

.hover-gray-muted:hover, .hover-gray-muted:focus { color: #d9dbe2; }

.hover-charcoal:hover, .hover-charcoal:focus { color: #34373f; }

.hover-charcoal-muted:hover, .hover-charcoal-muted:focus { color: #7f8491; }

.hover-red:hover, .hover-red:focus { color: #ea5037; }

.hover-red-muted:hover, .hover-red-muted:focus { color: #f36149; }

.hover-yellow:hover, .hover-yellow:focus { color: #f39021; }

.hover-yellow-muted:hover, .hover-yellow-muted:focus { color: #f9a13e; }

.hover-teal:hover, .hover-teal:focus { color: #3e9096; }

.hover-teal-muted:hover, .hover-teal-muted:focus { color: #439a9d; }

.hover-green:hover, .hover-green:focus { color: #0cb892; }

.hover-green-muted:hover, .hover-green-muted:focus { color: #0aca9f; }

.hover-snow:hover, .hover-snow:focus { color: #edf0f4; }

.hover-snow-muted:hover, .hover-snow-muted:focus { color: #f7f8fa; }

.hover-bg-navy:hover, .hover-bg-navy:focus { background-color: #0b3a53; }

.hover-bg-navy-muted:hover, .hover-bg-navy-muted:focus { background-color: #244e66; }

.hover-bg-aqua:hover, .hover-bg-aqua:focus { background-color: #69c4cd; }

.hover-bg-aqua-muted:hover, .hover-bg-aqua-muted:focus { background-color: #9ad4db; }

.hover-bg-gray:hover, .hover-bg-gray:focus { background-color: #b7bbc8; }

.hover-bg-gray-muted:hover, .hover-bg-gray-muted:focus { background-color: #d9dbe2; }

.hover-bg-charcoal:hover, .hover-bg-charcoal:focus { background-color: #34373f; }

.hover-bg-charcoal-muted:hover, .hover-bg-charcoal-muted:focus { background-color: #7f8491; }

.hover-bg-red:hover, .hover-bg-red:focus { background-color: #ea5037; }

.hover-bg-red-muted:hover, .hover-bg-red-muted:focus { background-color: #f36149; }

.hover-bg-yellow:hover, .hover-bg-yellow:focus { background-color: #f39021; }

.hover-bg-yellow-muted:hover, .hover-bg-yellow-muted:focus { background-color: #f9a13e; }

.hover-bg-teal:hover, .hover-bg-teal:focus { background-color: #3e9096; }

.hover-bg-teal-muted:hover, .hover-bg-teal-muted:focus { background-color: #439a9d; }

.hover-bg-green:hover, .hover-bg-green:focus { background-color: #0cb892; }

.hover-bg-green-muted:hover, .hover-bg-green-muted:focus { background-color: #0aca9f; }

.hover-bg-snow:hover, .hover-bg-snow:focus { background-color: #edf0f4; }

.hover-bg-snow-muted:hover, .hover-bg-snow-muted:focus { background-color: #f7f8fa; }

.fill-navy { fill: #0b3a53; }

.fill-navy-muted { fill: #244e66; }

.fill-aqua { fill: #69c4cd; }

.fill-aqua-muted { fill: #9ad4db; }

.fill-gray { fill: #b7bbc8; }

.fill-gray-muted { fill: #d9dbe2; }

.fill-charcoal { fill: #34373f; }

.fill-charcoal-muted { fill: #7f8491; }

.fill-red { fill: #ea5037; }

.fill-red-muted { fill: #f36149; }

.fill-yellow { fill: #f39021; }

.fill-yellow-muted { fill: #f9a13e; }

.fill-teal { fill: #3e9096; }

.fill-teal-muted { fill: #439a9d; }

.fill-green { fill: #0cb892; }

.fill-green-muted { fill: #0aca9f; }

.fill-snow { fill: #edf0f4; }

.fill-snow-muted { fill: #f7f8fa; }

.hover-fill-navy:hover, .hover-fill-navy:focus { fill: #0b3a53; }

.hover-fill-navy-muted:hover, .hover-fill-navy-muted:focus { fill: #244e66; }

.hover-fill-aqua:hover, .hover-fill-aqua:focus { fill: #69c4cd; }

.hover-fill-aqua-muted:hover, .hover-fill-aqua-muted:focus { fill: #9ad4db; }

.hover-fill-gray:hover, .hover-fill-gray:focus { fill: #b7bbc8; }

.hover-fill-gray-muted:hover, .hover-fill-gray-muted:focus { fill: #d9dbe2; }

.hover-fill-charcoal:hover, .hover-fill-charcoal:focus { fill: #34373f; }

.hover-fill-charcoal-muted:hover, .hover-fill-charcoal-muted:focus { fill: #7f8491; }

.hover-fill-red:hover, .hover-fill-red:focus { fill: #ea5037; }

.hover-fill-red-muted:hover, .hover-fill-red-muted:focus { fill: #f36149; }

.hover-fill-yellow:hover, .hover-fill-yellow:focus { fill: #f39021; }

.hover-fill-yellow-muted:hover, .hover-fill-yellow-muted:focus { fill: #f9a13e; }

.hover-fill-teal:hover, .hover-fill-teal:focus { fill: #3e9096; }

.hover-fill-teal-muted:hover, .hover-fill-teal-muted:focus { fill: #439a9d; }

.hover-fill-green:hover, .hover-fill-green:focus { fill: #0cb892; }

.hover-fill-green-muted:hover, .hover-fill-green-muted:focus { fill: #0aca9f; }

.hover-fill-snow:hover, .hover-fill-snow:focus { fill: #edf0f4; }

.hover-fill-snow-muted:hover, .hover-fill-snow-muted:focus { fill: #f7f8fa; }

.border-navy, .b--navy { border-color: #0b3a53; }

.border-navy-muted, .b--navy-muted { border-color: #244e66; }

.border-aqua, .b--aqua { border-color: #69c4cd; }

.border-aqua-muted, .b--aqua-muted { border-color: #9ad4db; }

.border-gray, .b--gray { border-color: #b7bbc8; }

.border-gray-muted, .b--gray-muted { border-color: #d9dbe2; }

.border-charcoal, .b--charcoal { border-color: #34373f; }

.border-charcoal-muted, .b--charcoal-muted { border-color: #7f8491; }

.border-red, .b--red { border-color: #ea5037; }

.border-red-muted, .b--red-muted { border-color: #f36149; }

.border-yellow, .b--yellow { border-color: #f39021; }

.border-yellow-muted, .b--yellow-muted { border-color: #f9a13e; }

.border-teal, .b--teal { border-color: #3e9096; }

.border-teal-muted, .b--teal-muted { border-color: #439a9d; }

.border-green, .b--green { border-color: #0cb892; }

.border-green-muted, .b--green-muted { border-color: #0aca9f; }

.border-snow, .b--snow { border-color: #edf0f4; }

.border-snow-muted, .b--snow-muted { border-color: #f7f8fa; }

.ipfs-gradient-0 { background-image: linear-gradient(to top, #041727 0%,#043b55 100%); }

.transition-all {
  transition: .2s ease all;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.force-select {
  -webkit-user-select: all;
     -moz-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

.no-pointer-events {
  pointer-events: none;
}

.focus-underline:focus {
  text-decoration: underline;
}

.focus-outline:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(201, 210, 215, .4);
}

.focus-outline-red:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(243, 97, 73, 0.4);
}

.focus-outline-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(10, 202, 159, 0.4);
}

.stroke-current-color {
  stroke: currentColor;
}

.fill-current-color {
  fill: currentColor;
}

.fill-white {
  fill: white;
}


